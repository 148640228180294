<template>
<v-col cols="12" class="mt-2">
<v-row class="d-flex justify-center">
      <v-col cols="3"><v-divider></v-divider></v-col>
    </v-row>

<v-row>
  <v-col cols="12">
          <v-row v-if="!ver">
            <v-col sm="6" cols="12" class="order-sm-2" v-if="!primero">
              <v-btn
                :disabled="invalid"
                :color="colores.primario"
                type="button"
                @click="$emit('GC')"
                class="white--text"
                >Guardar y continuar</v-btn
              >
            </v-col>
            <v-col cols="12" class="d-flex justify-center" v-if="primero">
              <v-btn
                :disabled="invalid"
                :color="colores.primario"
                type="button"
                @click="$emit('GC')"
                class="white--text"
                >Guardar y continuar</v-btn
              >
            </v-col>
            <v-spacer> </v-spacer>
            <v-col sm="6" cols="12" class="order-sm-1" v-if="!primero">
              <v-btn
                color="teal darken-1"
                class="white--text"
                :disabled="invalid"
                type="button"
                @click="$emit('GA')"
                >Guardar y Atrás </v-btn
              >
            </v-col>

            <v-col cols="12" class="order-sm-3" v-if="!primero">
              <v-btn color="error" type="button" @click="$emit('stepper', page-1)"
                >Atrás</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="ver">
            <v-col cols="6">
              <v-btn color="error" type="button" @click="$emit('stepper', page-1)"
                >Atrás</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                color="teal darken-1"
                dark
                type="button"
                @click="$emit('stepper', page+1)"
                >Siguiente </v-btn
              >
            </v-col>
          </v-row>
        </v-col>
</v-row>
</v-col>
        
    
</template>
<script>
export default {
    props:{page:{},invalid:{},colores:{},ver:{},primero:{
      default:false
    }},
    data() {
        return {
            
        }
    },
}
</script>